.c-form {
  &-box {
    margin-bottom: 3rem;

    &__title {
      font-family: "Roboto", "Arial";
      font-size: 1rem;
      font-weight: 700;
      line-height: 1.5rem;
    }

    &__input {
      width: 100%;

      display: flex;
      flex-direction: column;

      margin-bottom: 1rem;

      &-label {
        margin-bottom: 0.25rem;

        font-family: "Roboto", "Arial";
        font-size: 0.85rem;
        font-weight: 400;
        line-height: calc(0.85rem * 1.5);
      }

      &-input {
        height: 32px;
        width: 100%;

        padding: 0 0.5rem;

        border: none;
        border-radius: 8px;

        font-family: "Roboto", "Arial";
        font-size: 0.85rem;
        font-weight: 400;
        line-height: calc(0.85rem * 1.5);

        background-color: map-get($colors-neutral, "20");
      }

      &-textarea {
        width: 100%;

        padding: 0.5rem 0.5rem;

        border: none;
        border-radius: 8px;

        resize: none;

        font-family: "Roboto", "Arial";
        font-size: 0.85rem;
        font-weight: 400;
        line-height: calc(0.85rem * 1.5);

        background-color: map-get($colors-neutral, "20");
      }

      &--half {
        width: calc(50% - 1rem);

        :last-child {
          width: 100%;
        }
      }

      &--small {
        width: 20%;

        :last-child {
          width: 100%;
        }
      }
    }

    &--flex {
      width: 100%;

      display: flex;
      justify-content: space-between;
    }

    &__radio {
      margin-bottom: 1rem;

      &-title {
        margin-bottom: 0.5rem;

        font-family: "Roboto", "Arial";
        font-size: 0.85rem;
        font-weight: 400;
        line-height: calc(0.85rem * 1.5);
      }

      &-group {
        display: flex;
        align-items: center;

        margin-bottom: 0.15rem;
      }

      &-input {
        margin-right: 0.5rem;
      }

      &-label {
        font-family: "Roboto", "Arial";
        font-size: 0.85rem;
        font-weight: 400;
        line-height: calc(0.85rem * 1.5);
      }
    }

    &__upload {
      margin-bottom: 1rem;

      &-label {
        font-family: "Roboto", "Arial";
        font-size: 0.85rem;
        font-weight: 400;
        line-height: calc(0.85rem * 1.5);
      }
    }
  }

  &-submit {
    padding: 0.75rem 2rem;

    border: none;
    border-radius: 8px;

    cursor: pointer;

    font-family: "Roboto", "Arial";
    font-size: 0.85rem;
    font-weight: 600;
    line-height: calc(0.85rem * 1.5);

    color: map-get($colors-neutral, "0");
    background-color: map-get($colors-main, "main");

    &:hover {
      color: map-get($colors-main, "main");
      background-color: map-get($colors-neutral, "0");
    }

    &--light {
      margin-top: 4rem;

      background-color: map-get($colors-neutral, "100");

      &:hover {
        color: map-get($colors-neutral, "0");
        background-color: map-get($colors-neutral, "100");
      }
    }
  }
}
