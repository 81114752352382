.dark {
  background-color: map-get($colors-main, "main");
}

#g-recaptcha-response {
  display: block !important;
  position: absolute;
  margin: -78px 0 0 0 !important;
  width: 302px !important;
  height: 76px !important;
  z-index: -999999;
  opacity: 0;
}

.c-content {
  &-container {
    padding: 5rem 1.5rem;

    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-text {
    max-width: 600px;

    &__title {
      margin: 0;

      font-family: "Roboto", "Arial";
      font-size: 3rem;
      font-style: italic;
      font-weight: 700;
      line-height: 4.5rem;

      color: map-get($colors-neutral, "0");
    }

    &__subtitle {
      margin: 4rem 0 0 0;

      font-family: "Roboto", "Arial";
      font-size: 1.1rem;
      font-weight: 600;
      line-height: 1.5rem;

      color: map-get($colors-neutral, "0");
    }

    &__paragraph {
      margin: 1rem 0 0 0;

      font-family: "Roboto", "Arial";
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;

      color: map-get($colors-neutral, "0");

      a {
        color: map-get($colors-main, "dark");
      }

      &--sub {
        font-size: .85em;

        opacity: .5;
      }
    }

    &__link {
      margin: 1rem 0 0 0;

      font-family: "Roboto", "Arial";
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;

      &-link {
        display: flex;
        align-items: center;

        margin: 0;
        margin-bottom: 0.5rem;

        color: map-get($colors-neutral, "0");
        text-decoration: none;

        &:hover {
          color: map-get($colors-main, "dark");
          text-decoration: underline;

          svg {
            fill: map-get($colors-main, "dark");
          }
        }

        &-icon {
          height: 1.5rem;

          margin-right: 0.5rem;
        }
      }
    }
  }

  &-images {
    margin: 3rem 0 1rem 0;
    max-width: 700px;
    max-height: 700px;

    &__image {
      width: 100%;
      max-height: 700px;

      object-fit: cover;

      background-color: map-get($colors-main, "main");
    }

    &__credit {
      font-family: "Roboto", "Arial";
      font-size: .8rem;

      opacity: .8;
    }

    &-grid {
      max-width: 940px;
      width: 100%;

      display: grid;
      grid-template-columns: 1fr;
      column-gap: 20px;
      row-gap: 20px;
      justify-content: center;

      margin: 3rem 0 1rem 0;

      &__item {
        display: block;

        &-image {
          width: 100%;
          height: 300px;

          object-fit: cover;
        }

        &-icons {
          display: flex;
          flex-direction: row;
        }

        &-hover {
          margin-top: -300px;

          position: relative;

          width: 100%;
          height: 300px;

          display: none;
          justify-content: center;
          align-items: center;

          background-color: rgba($color: #000000, $alpha: 0.4);

          &-icon {
            width: 90px;
            height: 90px;

            fill: map-get($colors-neutral, "100");
            opacity: 0.95;

            &:hover {
              opacity: 1;

              fill: map-get($colors-main, "main");
            }
          }
        }
      }

      &__item:hover &__item-hover {
        display: flex;
      }
    }
  }

  &-workshops {
    margin: 2rem 0 0;

    &-grid {
      max-width: calc(800px + 2rem);
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      &__item {
        position: relative;

        width: 100%;
        max-width: 400px;
        height: 300px;

        margin: 0 0.5rem 1rem;

        &-overlay {
          position: absolute;
          z-index: 10000;

          width: calc(100% - 2rem);
          height: 100%;
          max-height: calc(300px - 2rem);

          display: flex;
          flex-direction: column;
          justify-content: flex-end;

          padding: 1rem;

          background-color: rgba($color: #000000, $alpha: 0.5);

          &-flex {
            display: flex;
            justify-content: space-between;
          }

          &__title {
            margin: 0;

            font-family: "Roboto", "Arial";
            font-size: 2rem;
            font-style: italic;
            font-weight: 700;
            line-height: 3rem;

            color: map-get($colors-neutral, "100");
          }

          &__subtitle {
            margin: 0;

            font-family: "Roboto", "Arial";
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5rem;

            color: map-get($colors-neutral, "100");

            &--dark {
              color: map-get($colors-neutral, "0");
            }
          }

          &__button {
            width: 100%;
            height: 2rem;

            display: flex;
            justify-content: center;
            align-items: center;

            margin: 1rem 0 0;

            border: none;
            border-radius: 4px;

            text-decoration: none;

            background-color: map-get($colors-main, "main");

            &--link {
              margin: 0;

              font-family: "Roboto", "Arial";
              font-size: 1rem;
              font-style: italic;
              font-weight: 600;
              line-height: 1.5rem;

              text-decoration: none;

              color: map-get($colors-neutral, "100");
            }
          }
        }

        &-image {
          width: 100%;
          height: 100%;

          object-fit: cover;
        }
      }
    }
  }
}

.c-map {
  width: 100%;
  max-width: 600px;
  height: 400px;
}

@media (min-width: 600px) {
  .c-content {
    &-container {
      padding: 7rem 1.5rem;
    }

    &-images {
      &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
        justify-content: center;
      }
    }
  }
}

@media (min-width: 1024px) {
  .c-content {
    &-container {
      padding: 7rem 1.5rem;
    }

    &-images {
      &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
        justify-content: center;
      }
    }
  }
}
