.c-header {
  display: block;
  width: 100%;

  padding-top: 80px;

  &-container {
    position: initial;

    width: 100%;
    height: 80vh;
  }

  &-image {
    position: absolute;
    z-index: 0;

    width: 100%;
    height: 80vh;

    object-fit: cover;

    background-color: map-get($colors-main, "main");
  }

  &-darker-layer {
    position: absolute;
    z-index: 10;

    width: 100%;
    height: 80vh;

    background-color: map-get($colors-neutral, "0");
    opacity: 0.3;
  }

  &-content {
    position: absolute;
    z-index: 100;

    width: 100%;
    height: 80vh;

    display: flex;
    justify-content: center;
    align-items: center;

    &__title {
      margin: 0;

      font-family: "Roboto", "Arial";
      font-size: 3rem;
      font-weight: 700;
      line-height: 6rem;

      text-align: center;

      color: map-get($colors-neutral, "100");
    }
  }
}

@media (min-width: 900px) {
  .c-header {
    &-content {
      &__title {
        font-size: 6rem;
        line-height: 9rem;
      }
    }
  }
}
