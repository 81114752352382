.c-footer {
  width: 100%;
  min-height: 210px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 6rem;
  padding: 1rem 0;

  background-color: #111111;

  &-content {
    width: 100%;
    max-width: 600px;

    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 0;

    &-logo {
      width: 70%;

      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 32px;

      & a {
        cursor: pointer;

        text-decoration: none;

        & svg {
        height: 80px;

        margin-right: 2rem;

        }

        &:last-child {
          & svg {
            margin: 0;
          }
        }
      }
    }

    &-sitemap {
      width: 80%;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &__column {
        & + & {
          margin-left: 6rem;
        }
      }

      &__subtitle {
        font-family: "Roboto", "Arial";
        font-size: 0.9rem;
        line-height: calc(0.9rem * 1.5);

        color: map-get($colors-neutral, "100");

        &:hover {
          color: map-get($colors-main, "main");
        }

        &--link {
          all: unset;

          color: inherit;

          cursor: pointer;
        }
      }
    }
  }

  &-socials {
    width: 100%;

    display: flex;
    justify-content: center;

    margin-top: 1.5rem;

    &__link {
      text-decoration: none;

      &-icon {
        width: 30px;

        margin: 0 0.5rem;

        fill: map-get($colors-neutral, "100");

        &:hover {
          fill: map-get($colors-main, "main");
        }
      }
    }
  }
}

@media (min-width: 600px) {
  .c-footer {
    &-content {
      display: flex;
      flex-direction: row;

      &-logo {
        width: 50%;

        margin-top: 0;
      }

      &-sitemap {
        width: 50%;

        justify-content: flex-end;
      }
    }
  }
}