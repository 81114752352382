.hidden {
  display: none;
}

.c-nav {
  position: fixed;
  z-index: 100000;

  width: 100%;
  height: 80px;

  -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
  -moz-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.07);

  background-color: map-get($colors-neutral, "100");

  &-container {
    height: 100%;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: 0 1rem;

    &__item {
      &-first {
        display: block;
      }

      &-second {
        display: block;
      }

      &-third {
        display: none;
      }

      a {
        text-decoration: none;
      }
    }
  }

  &-hamburger {
    width: 32px;
    height: 32px;
  }

  &-logo {
    width: 60px;

    text-decoration: none;

    &-sponsor {
      width: 200px;

      display: none;

      margin-left: 24px;
    }
  }

  &-links {
    display: flex;
    flex-direction: row;
    align-items: center;

    list-style: none;

    &__item {
      margin-left: 2rem;

      cursor: pointer;

      &-link {
        font-family: "Roboto", "Arial";
        font-weight: 700;
        font-size: 0.9rem;

        color: map-get($colors-neutral, "0");

        text-decoration: none;

        &:hover {
          border-bottom: 3px solid map-get($colors-neutral, "0");
        }
      }

      &--button {
        padding: 12px 32px;

        border-radius: 8px;

        background-color: map-get($colors-main, "main");

        &:hover{
          background-color: map-get($colors-neutral, "0");

          & a {
            color: map-get($colors-main, "main");

            border-bottom: none;
          }
        }
      }
    }
  }
}

.c-nav-mobile {
  position: fixed;
  z-index: 1000000;

  width: 100%;
  height: 100vh;

  background-color: map-get($colors-neutral, "100");

  &-close {
    position: absolute;
    z-index: 10000000;

    height: 80px;

    display: flex;
    align-items: center;

    &__button {
      width: 32px;
      height: 32px;

      margin: 0 1rem;
    }
  }

  &-links {
    height: 100%;

    z-index: 1000000;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    padding: 0;
    margin: 0 0 0 1rem;

    list-style: none;

    &__item {
      &-link {
        font-family: "Roboto", "Arial";
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 2.5rem;

        color: map-get($colors-neutral, "0");
        text-decoration: none;
      }
    }

    &__logo {
      position: absolute;

      display: flex;
      flex-direction: row;
      align-items: center;

      bottom: 0;

      a {
        text-decoration: none;
      }

      &-svg {
        width: 200px;

        margin-left: 1rem;
      }
    }
  }
}

@media (min-width: 600px) {
  .c-nav {
    &-container {
      margin: 0 4rem;

      &__item {
        &-first {
          display: none;
        }

        &-second {
          display: flex;
          align-items: center;
        }

        &-third {
          display: block;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .c-nav {
    &-logo {
      &-sponsor {
        display: block;
      }
    }
  }
}